import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
// import { API_BASE_URL } from "./Constants"

// console.log(process.env.REACT_APP_BASE_URL);

const baseQuery = fetchBaseQuery({
  // baseUrl: API_BASE_URL,
  baseUrl: process.env.REACT_APP_BASE_URL,
  // baseUrl: "https://growlex.el.r.appspot.com/api",
  // baseUrl: "https://34.93.197.118/api",
  // baseUrl: "https://billex.in/api",
  // baseUrl: "https://growlex.cloud/api",

  // Comment:- Please share X_API_KEY and Auth token here.

  // prepareHeaders: async (headers, { getState, endpoint }) => {
  // 	const { token } = getState().common
  // 	if (token && !["queryname"].includes(endpoint)) {
  // 		headers.append("Authorization", `Bearer ${token}`)
  // 	}
  // 	return headers
  // },
});
// const baseQueryWithInterceptor = async (args, api, extraOptions) => {
// 	const result = await baseQuery(args, api, extraOptions)
// 	if (result.error && result.error.originalStatus === 401) {
// 		toast.error("Unauthorized!")
// 		window.localStorage.clear()
// 		setTimeout(() => {
// 			window.location.reload()
// 		}, 3000)
// 	}
// 	return result
// }
export const baseApi = createApi({
  // reducerPath: "baseApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
