import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://jsonplaceholder.typicode.com",
	}),
	tagTypes: ["Users"],
	endpoints: (builder) => ({
		getPosts: builder.query({
			query: () => "posts",
			providesTags: ["Users"],
		}),
		// createPosts: builder.mutation({
		// 	query: (payload) => ({
		// 		url: "posts",
		// 		method: "POST",
		// 		body: payload,
		// 	}),
		// 	invalidatesTags: ["Users"],
		// }),
	}),
})

export const { useGetPostsQuery, useCreatePostsMutation } = apiSlice
