import React, { useState, useRef } from "react";
import "./Feedback.scss";
import super_happy from "../../assets/images/emojis/super_happy.svg";
import cancel_icon from "../../assets/images/emojis/cancel_icon.svg";
import happy from "../../assets/images/emojis/happy.svg";
import angry from "../../assets/images/emojis/angry.svg";
import dislike from "../../assets/images/emojis/dislike.svg";
import love_it from "../../assets/images/emojis/love_it.svg";
import ok from "../../assets/images/emojis/ok.svg";
import { Link } from "react-router-dom";
import BtnLoader1 from "../loadersComp/btnLoader1/BtnLoader1";
import { useSelector } from "react-redux";
import { useGetWebsiteFeedbackApiMutation } from "../../slices/querySlices/postLoginThreeSlice";

const Feedback = ({ handleModal, showModal }) => {
  const [feedbackState, setFeedbackState] = useState(0);
  const feedbackTextRef = useRef();
  const [emojiData, setEmojiData] = useState({ id: "", type: "" });
  const userIPAddress = useSelector((store) => store.userDetail.userIPAddress);
  const browserType = useSelector((store) => store.userDetail.browserType);

  const [getWebsiteFeedbackApi, { isLoading: isLoadingFeebackApi }] =
    useGetWebsiteFeedbackApiMutation();

  const emojiArr = [
    {
      emoji: angry,
      title: "Angry",
    },
    {
      emoji: dislike,
      title: "Dislike",
    },
    {
      emoji: ok,
      title: "Ok",
    },
    {
      emoji: happy,
      title: "Happy",
    },
    {
      emoji: love_it,
      title: "Love it",
    },
  ];

  const handleEmojiData = ({ id, title }) => {
    setEmojiData({
      id,
      type: title,
    });
  };

  const handleFeedbackState = (val) => {
    setFeedbackState(val);
  };

  const handleEmojiClick = (id, title, val) => {
    handleFeedbackState(val);
    handleEmojiData({ id, title });
  };

  const toggleFeedbackModal = () => {
    handleEmojiData({ id: "", title: "" });
    handleModal();
    setTimeout(() => {
      handleFeedbackState(0);
    }, 500);
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData(e.target);

    // const formObject = {};

    // formData.forEach((value, key) => {
    //   formObject[key] = value;
    // });

    try {
      const res = await getWebsiteFeedbackApi({
        reaction: emojiData.type,
        comment: feedbackTextRef.current.value,
        ip_address: userIPAddress,
      });

      if (res.data.status === 200) {
        handleFeedbackState(2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const store_link =
    browserType === "Safari"
      ? "https://apps.apple.com/in/app/growlex/id6569262898"
      : "https://play.google.com/store/apps/details?id=com.gift.growlex";

  // console.log(emojiData);

  const CustComp1 = () => {
    return (
      <div className="custComp1">
        <p className="util_p1_para custComp1_title">
          How would you rate your experience?
        </p>
        <div className="emojisCont">
          {emojiArr.map((data, index) => {
            return (
              <div
                className="emoji"
                key={index}
                onClick={() => handleEmojiClick(index, data.title, 1)}
              >
                <img src={data.emoji} alt={data.emoji} />
                <p className="util_p2_para">{data.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const CustComp2 = () => {
    return (
      <div className="custComp2">
        <div className="emojisCont">
          {emojiArr.map((data, index) => {
            return (
              <div
                className="emoji"
                key={index}
                onClick={() =>
                  handleEmojiData({ id: index, title: data.title })
                }
              >
                <img
                  src={data.emoji}
                  alt={data.emoji}
                  className={`${emojiData.id === index ? "selectedEmoji" : ""}`}
                />
                <p className="util_p2_para">{data.title}</p>
              </div>
            );
          })}
        </div>
        <div className="feedback_form">
          <textarea
            className="util_p2_para"
            placeholder="Tell us how can we improve?"
            rows="4"
            required
            name="feedbackTextName"
            ref={feedbackTextRef}
          />
          <button
            type="submit"
            className="util_h5_heading"
            style={{
              backgroundColor: isLoadingFeebackApi ? "var(--neutral-900)" : "",
              paddingBlock: isLoadingFeebackApi ? "0" : "0.5rem",
            }}
            onClick={handleFeedbackSubmit}
          >
            {isLoadingFeebackApi ? <BtnLoader1 /> : "Send"}
          </button>
        </div>
      </div>
    );
  };

  const CustComp3 = () => {
    return (
      <div className="custComp3">
        <p className="util_p1_para custComp3_title">
          Thank you for sharing your feedback with us!
        </p>
        <a href={store_link} target="_blank" className="ctaBtn">
          <h5 className="util_h5_heading">Earn up to 14% returns</h5>
        </a>
      </div>
    );
  };

  return (
    <div
      className={`feedback_wrapper ${showModal && "open"}`}
      onClick={toggleFeedbackModal}
    >
      <div className={`feedback`} onClick={(e) => e.stopPropagation()}>
        <div className="feedback_top">
          <img
            src={cancel_icon}
            alt="cancel_icon"
            width="26.67"
            height="28.78"
            onClick={toggleFeedbackModal}
          />
        </div>
        <div className="feedback_bottom">
          {feedbackState === 0 && <CustComp1 />}
          {feedbackState === 1 && <CustComp2 />}
          {feedbackState === 2 && <CustComp3 />}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
