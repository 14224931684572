import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  authToken: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    addUserId: (state, action) => {
      state.userId = action.payload;
    },

    addAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { addUserId, addAuthToken } = commonSlice.actions;
export default commonSlice.reducer;
