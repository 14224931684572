import { baseApi } from "./baseApi";

export const postLoginThreeSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    goalsCategoryApi: builder.mutation({
      query: (payload) => {
        return {
          url: "goals-categories",
          method: "POST",
          body: payload,
        };
      },
    }),

    goalsCreateScreenApi: builder.mutation({
      query: (payload) => {
        return {
          url: "goals-create-screen",
          method: "POST",
          body: payload,
        };
      },
    }),

    goalsCreateGoalsApi: builder.mutation({
      query: (payload) => {
        return {
          url: "goals-create-goal",
          method: "POST",
          body: payload,
        };
      },
    }),

    goalsSummaryPageApi: builder.mutation({
      query: (payload) => ({
        url: "goals-check-pending-goal",
        method: "POST",
        body: payload,
      }),
    }),

    goalsHomeApi: builder.mutation({
      query: (payload) => ({
        url: "goals-home",
        method: "POST",
        body: payload,
      }),
    }),

    privacyPolicyApi: builder.mutation({
      query: (payload) => ({
        url: "privacy-policy-web",
        method: "GET",
        body: payload,
      }),
    }),

    giftCardsInfoApi: builder.mutation({
      query: (payload) => ({
        url: "giftcards-home",
        method: "POST",
        body: payload,
      }),
    }),

    giftCardsApi: builder.mutation({
      query: (payload) => {
        const { page } = payload;
        return {
          url: "giftcards",
          method: "POST",
          body: payload,
          params: { page },
        };
      },
    }),

    giftCardScratchApi: builder.mutation({
      query: (payload) => ({
        url: "giftcard-scratch",
        method: "POST",
        body: payload,
      }),
    }),

    giftCardChooseBrandApi: builder.mutation({
      query: (payload) => {
        const { page } = payload;
        return {
          url: "giftcard-fetch",
          method: "POST",
          body: payload,
          params: { page },
        };
      },
    }),

    giftCardOrderApi: builder.mutation({
      query: (payload) => ({
        url: "giftcard-order",
        method: "POST",
        body: payload,
      }),
    }),

    giftCardViewApi: builder.mutation({
      query: (payload) => ({
        url: "giftcard-view",
        method: "POST",
        body: payload,
      }),
    }),

    getGiftCardCategories: builder.query({
      query: () => "giftcard-categories",
    }),

    getWebsiteFeedbackApi: builder.mutation({
      query: (payload) => ({
        url: "feedback-save",
        method: "POST",
        body: payload,
      }),
    }),

    giftcardMarkAsUsedApi: builder.mutation({
      query: (payload) => ({
        url: "giftcard-mark-as-used",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGoalsCategoryApiMutation,
  useGoalsCreateScreenApiMutation,
  useGoalsCreateGoalsApiMutation,
  useGoalsSummaryPageApiMutation,
  useGoalsHomeApiMutation,
  usePrivacyPolicyApiMutation,
  useGiftCardsInfoApiMutation,
  useGiftCardsApiMutation,
  useGiftCardScratchApiMutation,
  useGiftCardChooseBrandApiMutation,
  useGiftCardOrderApiMutation,
  useGiftCardViewApiMutation,
  useGetGiftCardCategoriesQuery,
  useGetWebsiteFeedbackApiMutation,
  useGiftcardMarkAsUsedApiMutation,
} = postLoginThreeSlice;

// ScreenApi: builder.mutation({
//   query: (payload) => ({
//     url: "",
//     method: "POST",
//     body: payload,
//   }),
// }),
