import React, { useEffect, useState } from "react";
import super_happy from "../../../assets/images/emojis/super_happy.svg";
import happy from "../../../assets/images/emojis/happy.svg";
import angry from "../../../assets/images/emojis/angry.svg";
import dislike from "../../../assets/images/emojis/dislike.svg";
import love_it from "../../../assets/images/emojis/love_it.svg";
import ok from "../../../assets/images/emojis/ok.svg";
import "./FeedbackLabelMob.scss";

const FeedbackLabelMob = ({ handleModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [love_it, super_happy, happy, ok, dislike, angry];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 1200);

    return () => clearInterval(timer);
  }, [currentIndex]);

  return (
    <div className="feedbackLabelMob" onClick={handleModal}>
      <img
        src={images[currentIndex]}
        alt={`emoji-${currentIndex}`}
        width={32}
        height={32}
      />
    </div>
  );
};

export default FeedbackLabelMob;
