import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  goalCategoryId: "",
  goalId: "",
  goalInvestmentType: 0,
};

const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    addGoalCategoryId: (state, action) => {
      state.goalCategoryId = action.payload;
    },

    addGoalId: (state, action) => {
      state.goalId = action.payload;
    },

    addGoalInvestmentType: (state, action) => {
      state.goalInvestmentType = action.payload;
    },
  },
});

export const { addGoalCategoryId, addGoalId, addGoalInvestmentType } =
  goalsSlice.actions;

export default goalsSlice.reducer;
