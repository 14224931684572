import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseApi } from "./baseApi";

export const preLoginPageSlice = baseApi.injectEndpoints({
  // tagTypes: ["Users"],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => "posts",
      // providesTags: ["Users"],
    }),
    sendOtpPoint: builder.mutation({
      query: (payload) => ({
        url: "send-otp",
        method: "POST",
        body: payload,
      }),
      // transform:
    }),
    verifyOtpPoint: builder.mutation({
      query: (payload) => ({
        url: "verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
    userSignupApi: builder.mutation({
      query: (payload) => ({
        url: "user-signup",
        method: "POST",
        body: payload,
      }),
    }),
    declicationHtmlApi: builder.mutation({
      query: (payload) => ({
        url: "declication-html",
        method: "POST",
        body: payload,
      }),
    }),
    signDeclarationApi: builder.mutation({
      query: (payload) => ({
        url: "sign-declaration",
        method: "POST",
        body: payload,
      }),
    }),
    getPanDetailsApi: builder.mutation({
      query: (payload) => ({
        url: "get-pan-details",
        method: "POST",
        body: payload,
      }),
    }),
    getExistingPanDetailsApi: builder.mutation({
      query: (payload) => ({
        url: "get-existing-pan-details",
        method: "POST",
        body: payload,
      }),
    }),
    addPanDetailsApi: builder.mutation({
      query: (payload) => ({
        url: "add-pan-details",
        method: "POST",
        body: payload,
      }),
    }),
    checkEmailExistsApi: builder.mutation({
      query: (payload) => ({
        url: "check-email-exists",
        method: "POST",
        body: payload,
      }),
    }),
    checkReferralExistsApi: builder.mutation({
      query: (payload) => ({
        url: "check-referral-code-exists",
        method: "POST",
        body: payload,
      }),
    }),

    resumeCKycApi: builder.mutation({
      query: (payload) => ({
        url: "resume-ckyc",
        method: "POST",
        body: payload,
      }),
    }),

    manualKycDocUploadScreen: builder.mutation({
      query: (payload) => ({
        url: "manual-kyc-document-upload-screen",
        method: "POST",
        body: payload,
      }),
    }),

    manualKycDocReUploadScreen: builder.mutation({
      query: (payload) => ({
        url: "manual-kyc-document-upload-screen-round2-screen",
        method: "POST",
        body: payload,
      }),
    }),

    uploadManualKycDoc: builder.mutation({
      query: (payload) => ({
        url: "upload-manual-kyc-document",
        method: "POST",
        body: payload,
      }),
    }),

    submitManualKycDoc: builder.mutation({
      query: (payload) => ({
        url: "submit-manual-kyc-documents",
        method: "POST",
        body: payload,
      }),
    }),

    manualKycDocVerificationScreen: builder.mutation({
      query: (payload) => ({
        url: "manual-kyc-docs-screen",
        method: "POST",
        body: payload,
      }),
    }),

    skipCKycKycApi: builder.mutation({
      query: (payload) => ({
        url: "skip-ckyc-kyc",
        method: "POST",
        body: payload,
      }),
    }),

    contactUsManualKycFailApi: builder.mutation({
      query: (payload) => ({
        url: "contact-us-manual-kyc-failed",
        method: "POST",
        body: payload,
      }),
    }),

    contactUsWebApi: builder.mutation({
      query: (payload) => ({
        url: "contact-us-web",
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPostsQuery,
  useSendOtpPointMutation,
  useVerifyOtpPointMutation,
  useUserSignupApiMutation,
  useDeclicationHtmlApiMutation,
  useSignDeclarationApiMutation,
  useGetPanDetailsApiMutation,
  useGetExistingPanDetailsApiMutation,
  useAddPanDetailsApiMutation,
  useCheckEmailExistsApiMutation,
  useCheckReferralExistsApiMutation,
  useResumeCKycApiMutation,
  useManualKycDocUploadScreenMutation,
  useManualKycDocReUploadScreenMutation,
  useUploadManualKycDocMutation,
  useSubmitManualKycDocMutation,
  useManualKycDocVerificationScreenMutation,
  useSkipCKycKycApiMutation,
  useContactUsManualKycFailApiMutation,
  useContactUsWebApiMutation,
} = preLoginPageSlice;
