import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import commonReducer from "../slices/common/commonSlice";
import userDetailReducer from "../slices/userDetail/userDetailSlice";
import userPanDetailReducer from "../slices/userPanDetail/userPanDetailSlice";
import authReducer from "../slices/auth/authSlice";
import clubReducer from "../slices/club/clubSlice";
import goalsReducer from "../slices/goals/goalsSlice";
import { apiSlice } from "../slices/querySlices/apiSlice";
import { preLoginPageSlice } from "../slices/querySlices/preLoginPageSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { baseApi } from "../slices/querySlices/baseApi";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["common", "userDetail", "auth"],
  // whitelist: ["auth"],
};

const reducers = combineReducers({
  // common,
  // loader,
  // home,
  // squads,
  common: commonReducer,
  userDetail: userDetailReducer,
  userPanDetail: userPanDetailReducer,
  auth: authReducer,
  club: clubReducer,
  goals: goalsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware);
    return middlewares;
  },
});

const persistor = persistStore(store);
setupListeners(store.dispatch);
export { store, persistor };
