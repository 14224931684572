import React from "react";
import "./BtnLoader1.scss";
import Lottie from "lottie-react";
import test_loader from "../../../assets/loaders/test_loader.json";
import golden_loader from "../../../assets/loaders/golden_loader.json";

const BtnLoader1 = () => {
  return (
    <>
      {" "}
      <Lottie
        className="testLoader_lottie_json"
        animationData={golden_loader}
        loop={true}
        autoPlay={true}
        style={{ width: 50, height: 50 }}
      />
    </>
  );
};

export default BtnLoader1;
