import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNo: "",
  otp: "",
  nameInitial: "Mr.",
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  referral: "",
  userIPAddress: "",
  browserType: "",
  profileImg: "",
};

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    addPhoneNo: (state, action) => {
      state.phoneNo = action.payload;
      // console.log("payload: ", action.payload)
    },

    addOTP: (state, action) => {
      state.otp = action.payload;
      // console.log("payload: ", action.payload)
    },

    addNameInitial: (state, action) => {
      state.nameInitial = action.payload;
    },

    addFirstName: (state, action) => {
      state.firstName = action.payload;
    },

    addLastName: (state, action) => {
      state.lastName = action.payload;
    },

    addEmail: (state, action) => {
      state.email = action.payload;
    },

    addDob: (state, action) => {
      state.dob = action.payload;
    },

    addReferral: (state, action) => {
      state.referral = action.payload;
    },

    addUserIPAddress: (state, action) => {
      state.userIPAddress = action.payload;
    },

    addBrowserType: (state, action) => {
      state.browserType = action.payload;
    },

    addProfileImg: (state, action) => {
      state.profileImg = action.payload;
    },
  },
});

export const {
  addPhoneNo,
  addOTP,
  addNameInitial,
  addFirstName,
  addLastName,
  addEmail,
  addDob,
  addReferral,
  addUserIPAddress,
  addBrowserType,
  // addUserId,
  addIsDeclarationSigned,
  addIsCkycComplete,
  addProfileImg,
} = userDetailSlice.actions;
export default userDetailSlice.reducer;
