import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clubInvestmentType: "flexi",
  clubInvestmentTypeId: 3,
  clubInvestmentTypePer: "9.8",
  clubInvestmentSubType: "",
  clubInvestmentSubTypeId: 0,
  clubInvestmentSubTypePer: "",
  clubInvestmentPer: "9.8",
  clubInvestmentAmt: 0,
  clubInvestmentDuration: 0,
  clubInvestmentExpectedEarnings: "",
  clubInvestmentStatus: 0, // 0-not invested, 1-flexi, 2-vip, 3-both
  clubInvestedInLifetime: "0",
  clubNotifications: 0,
  clubOnboardingStatus: "",
  clubIsOldUser: 0,
  clubAccessUserDeatailsPage: 0,
  clubAccessUserDobPage: 0,
  clubFlexiCat: 0,
  clubIsVipMonthlyPayoutSelected: false,
  resumeCKyc: 0,
  isLBRegistred: 0,
  clubSetVipCatIndex: 0,
};

const clubSlice = createSlice({
  name: "club",
  initialState,
  reducers: {
    addClubInvestmentType: (state, action) => {
      state.clubInvestmentType = action.payload;
    },

    addClubInvestmentTypeId: (state, action) => {
      state.clubInvestmentTypeId = action.payload;
    },

    addClubInvestmentTypePer: (state, action) => {
      state.clubInvestmentTypePer = action.payload;
    },

    addClubInvestmentSubType: (state, action) => {
      state.clubInvestmentSubType = action.payload;
    },

    addClubInvestmentSubTypeId: (state, action) => {
      state.clubInvestmentSubTypeId = action.payload;
    },

    addClubInvestmentSubTypePer: (state, action) => {
      state.clubInvestmentSubTypePer = action.payload;
    },

    addClubInvestmentPer: (state, action) => {
      state.clubInvestmentPer = action.payload;
    },

    addClubInvestmentAmt: (state, action) => {
      state.clubInvestmentAmt = action.payload;
    },

    addClubInvestmentDuration: (state, action) => {
      state.clubInvestmentDuration = action.payload;
    },

    addClubInvestmentExpectedEarnings: (state, action) => {
      state.clubInvestmentExpectedEarnings = action.payload;
    },

    addClubInvestmentStatus: (state, action) => {
      state.clubInvestmentStatus = action.payload;
    },

    addClubInvestedInLifetime: (state, action) => {
      state.clubInvestedInLifetime = action.payload;
    },

    addClubNotifications: (state, action) => {
      state.clubNotifications = action.payload;
    },

    addClubOnboardingStatus: (state, action) => {
      state.clubOnboardingStatus = action.payload;
    },

    addClubIsOldUser: (state, action) => {
      state.clubIsOldUser = action.payload;
    },

    addClubAccessUserDeatailsPage: (state, action) => {
      state.clubAccessUserDeatailsPage = action.payload;
    },

    addClubAccessUserDobPage: (state, action) => {
      state.clubAccessUserDobPage = action.payload;
    },

    addClubFlexiCat: (state, action) => {
      state.clubFlexiCat = action.payload;
    },

    addClubIsVipMonthlyPayoutSelected: (state, action) => {
      state.clubIsVipMonthlyPayoutSelected = action.payload;
    },

    addResumeCKyc: (state, action) => {
      state.resumeCKyc = action.payload;
    },

    addIsLBRegistred: (state, action) => {
      state.isLBRegistred = action.payload;
    },

    addClubSetVipCatIndex: (state, action) => {
      state.clubSetVipCatIndex = action.payload;
    },
  },
});

export const {
  addClubInvestmentType,
  addClubInvestmentTypeId,
  addClubInvestmentTypePer,
  addClubInvestmentSubType,
  addClubInvestmentSubTypeId,
  addClubInvestmentSubTypePer,
  addClubInvestmentAmt,
  addClubInvestmentPer,
  addClubInvestmentDuration,
  addClubInvestmentExpectedEarnings,
  addClubInvestmentStatus,
  addClubInvestedInLifetime,
  addClubNotifications,
  addClubOnboardingStatus,
  addClubIsOldUser,
  addClubAccessUserDeatailsPage,
  addClubAccessUserDobPage,
  addClubFlexiCat,
  addClubIsVipMonthlyPayoutSelected,
  addResumeCKyc,
  addIsLBRegistred,
  addClubSetVipCatIndex,
} = clubSlice.actions;

export default clubSlice.reducer;
