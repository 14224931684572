import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	panNumber: "",
	panFullName: "",
	panDob: "",
	panFatherName: "",
	panAddress: "",
}

const userPanDetailSlice = createSlice({
	name: "userPanDetail",
	initialState,
	reducers: {
		addPanNumber: (state, action) => {
			state.panNumber = action.payload
		},

		addPanFullName: (state, action) => {
			state.panFullName = action.payload
		},

		addPanDob: (state, action) => {
			state.panDob = action.payload
		},

		addPanFatherName: (state, action) => {
			state.panFatherName = action.payload
		},

		addPanAddress: (state, action) => {
			state.panAddress = action.payload
		},
	},
})

export const {
	addPanNumber,
	addPanFullName,
	addPanDob,
	addPanFatherName,
	addPanAddress,
} = userPanDetailSlice.actions
export default userPanDetailSlice.reducer
