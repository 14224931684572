import React from "react"
import Lottie from "lottie-react"
import './GoldLoader.css'
import golden_loader from "../../../assets/loaders/golden_loader.json"

const GoldLoader = ({overlay}) => {
	const aboveInteractivity = {
		mode: "scroll",
		actions: [
			{
				visibility: [0, 1.0],
				type: "loop",
				frames: [0, 91],
			},
		],
	}

	return (
		<div className={"goldLoader " + (overlay ? "goldLoaderOverlay" : "")}>
			<div className="goldLoader_lottie">
				<Lottie
                    className="goldLoader_lottie_json"
					animationData={golden_loader}
					loop={true}
					autoPlay={true}
					// interactivity={aboveInteractivity}
				/>
			</div>
		</div>
	)
}

export default GoldLoader
