import React from "react";
import super_happy from "../../../assets/images/emojis/super_happy.svg";
import "./FeedbackLabelDesk.scss";

const FeedbackLabelDesk = ({ handleModal }) => {
  return (
    <div className="feedbackLabelDesk" onClick={handleModal}>
      <h5 className="util_h5_heading">Feedback</h5>
      <img src={super_happy} alt="super_happy" width={32} height={32} />
    </div>
  );
};

export default FeedbackLabelDesk;
