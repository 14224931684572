import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
// import 'react-tooltip/dist/react-tooltip.css'
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./store/store";

import GoldLoader from "./components/loadersComp/goldLoader/GoldLoader";
import FeedbackContextProvider from "./components/feedback/FeedbackContext";
// import App from './App';
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<GoldLoader />}>
          <FeedbackContextProvider>
            <App />
          </FeedbackContextProvider>
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
